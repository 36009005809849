import React from 'react';
import TradingGif from './assets/trading.gif';
import PepeGif from './assets/pepe.gif';
import CoinstackCol from './assets/coinstack_col.png';
import DollarCol from './assets/dollar_col.png';
import RocketCol from './assets/rocket_col.png';
import UpCol from './assets/up_col.png';
import './App.scss';

function App() {
  return (
    <div className="App">
      <div className="App-wrap">
        <img className="Animation-logo" src={TradingGif} alt="Logo Animation" />
        <div className="App-content">
          <div className="App-col">
            <div className="App-title">
              A Diversified Propriety
              Trading Firm in multiple
              asset classes
            </div>
            <p className="App-paragraph">
              Our team of experienced traders utilises advanced technology and quantitative strategies 
              to make data-driven decisions and capitalise on market opportunities.
            </p>
            <div className="App-contact">
              <address>
                <div className="App-contact_bg" />
                <a className="App-contact_link" href="mailto:admin@fisher8trading.com">
                  Contact us
                </a>
              </address>
            </div>
            <div className="Animation-img_rocket_dk">
              <img src={RocketCol} className="Animation-img" alt="" />
            </div>
            <div className="Animation-img_dollar_dk">
              <img src={DollarCol} className="Animation-img" alt="" />
            </div>
          </div>
          <div className="App-col">
            <div className="Animation-pepe">
              <img src={PepeGif} alt="Pepe Animation" />
              <div className="Animation-img_up">
                <img src={UpCol} className="Animation-img" alt="" />
              </div>
              <div className="Animation-img_coinstack">
                <img src={CoinstackCol} className="Animation-img" alt="" />
              </div>
              <div className="Animation-img_rocket_mb">
                <img src={RocketCol} className="Animation-img" alt="" />
              </div>
              <div className="Animation-img_dollar_mb">
                <img src={DollarCol} className="Animation-img" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
